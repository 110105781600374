// variables
$blue: #16c2d9;
$green: #1cad43;
$yellow: #fffb4a;
$orange: #f8903a;

// breakpoints
$small: 430px; //Mobile
$medium: 768px; //Tablet Portrait
$large: 1024px; //Tablet Landscape
$huge: 1280px; //Desktop

@mixin small {
  @media only screen and (max-width: $small) {
    @content;
  }
}

/* media query for tablet portrait */
@mixin medium {
  @media only screen and (min-width: $medium) {
    @content;
  }
}

/* media query for tablet landscape or medium pixel density desktops */
@mixin large {
  @media only screen and (min-width: $large) {
    @content;
  }
}

/* media query for high pixel density desktop */
@mixin huge {
  @media only screen and (min-width: $huge) {
    @content;
  }
}

* {
  font-family: "Roboto Mono";
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 1rem;
  text-align: center;
  font-size: 2rem;
  // font-family: "Press Start 2P", cursive;
  @include medium {
    padding: 0 15%;
  }
  @include large {
    padding: 0 20%;
  }
  &__logo {
    margin: 0 auto;
    max-width: 30%;
    @include medium {
      max-width: 20%;
      margin: 0 auto 0 0;
    }
  }
  a {
    color: $orange;
    text-decoration: none;
  }
}

.tracker {
  min-height: 100vh;
  font-size: 1.5rem;
  .planes-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
  }
  .plane {
    // height: 20vh;
    font-family: monospace;
    // font-size: 24px;
    border: 4px solid black;
    padding: 1rem;
    width: 90%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    &__delete {
      position: absolute;
      bottom: 5px;
      left: 5px;
      cursor: pointer;
      // font-size: 1rem;
    }
  }
  .stats {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &__stat {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      &-icon {
        max-width: 50px;
        // background-color: white;
        // padding: 0.2rem;
        // border: 4px solid black;
      }
      &-value {
        margin: 0;
        // font-size: 2.5rem;
      }
      &-decrease {
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border: 4px solid black;
        color: black !important;
        &:hover {
          background-color: #e7e7e7;
          cursor: pointer;
        }
      }
      &-increase {
        @extend .stats__stat-decrease;
      }
    }
  }

  .health {
    &__title {
      text-align: center;
      // // font-size: 1.5rem;
      margin-bottom: 1rem;
      color: unset;
      margin-top: 0;
      padding-top: 0;
    }
    &__controls {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      &-value {
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border: 4px solid black;
        color: black !important;
      }

      &-decrease,
      &-increase {
        @extend .health__controls-value;
        &:hover {
          background-color: #e7e7e7;
          cursor: pointer;
        }
      }
    }
  }

  .colour {
    display: flex;
    align-items: center;
    gap: 1rem;
    // // font-size: 1.5rem;
    margin-bottom: 1.5rem;

    // &__title {
    // //   font-size: 0.75rem;
    // }
    &__bg {
      border: 4px solid black;
      -webkit-appearance: none;
      appearance: none;
      width: 32px;
      height: 32px;
      padding: 0;
      &::-webkit-color-swatch-wrapper {
        padding: 0;
      }
      &::-webkit-color-swatch {
        border: none;
      }
    }
  }
  .add-btn,
  .ready-btn {
    font-family: monospace;
    margin-top: 1.5rem;
    border: 4px solid black;
    background-color: white;
    padding: 1rem;
    font-size: 2.5rem;
    &:hover {
      background-color: black;
      color: white;
    }
  }

  .add-btn {
    @extend .ready-btn;
    margin: 1.5rem auto;
    display: block;
    // // font-size: 1.5rem;
  }

  h1,
  p {
    text-align: center;
  }
}

button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Stint Ultra Expanded", monospace;
}
